const axios = require('axios');

const descDivider = desc => (desc ? desc.split(/\r?\n/) : []);

const bringUpSubscribsionModal = (desc) => {
  window.dojoRequire(['mojo/signup-forms/Loader'], (L) => {
    L.start({
      baseUrl: 'mc.us17.list-manage.com',
      uuid: 'd1c65d0c4becae18f4013777e',
      lid: '4b73a9f6c8',
      uniqueMethods: true,
    });
  });
};

const makeRequest = (requestObj) => {
  const config = {
    baseURL: 'https://imagiroconcepts.com/', // DEBUG
    responseType: 'json',
    headers: {
      'Content-Type': requestObj.contentType || 'application/x-www-form-urlencoded',
      ...requestObj.headers,
    },
    url: 'api/formHandler',
    data: requestObj.data || {},
    params: requestObj.params || {},
    method: requestObj.method || 'POST',
  };

  return axios(config)
    .then((it) => {
      console.log(`return from: ${requestObj.endpoint}`);
      return it;
    })
    .then(resp => resp);
};

exports.bringUpSubscribsionModal = bringUpSubscribsionModal;
exports.descDivider = descDivider;
exports.makeRequest = makeRequest;
