import React from 'react';
import Box from '../Atoms/Box';

import Gold from '../../assets/icons/gold-angle.svg';
import theme from '../../styles/theme';
import FlexBox from '../Atoms/FlexBox';
import Paragraph from '../Atoms/Paragraph';

function EnquiryFormHeading(props) {
  const { title, ...rest } = props;
  return (
    <FlexBox width="100%" borderBottom={`1px solid ${theme.color.black}`} py={1} mb={[4, 4, 6]} {...rest}>
      <Box flex="0 0 24px" height={24}>
        {title && <Gold />}
      </Box>
      {title && (
      <Box flex={1} ml={2}>
        <Paragraph fontWeight={700} fontSize={1} pb={0}>
          {title}
        </Paragraph>
      </Box>
      )}
    </FlexBox>
  );
}

export default EnquiryFormHeading;
