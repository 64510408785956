import React from 'react';
import styled from 'styled-components';
import { fontSize } from 'styled-system';

const StyledField = styled.div`
  padding-right: 40px;
  flex: 0 0 250px;
  label {
    color: ${props => props.theme.color.gray};
    ${fontSize}
  }
  .radio {
    display: block;
    margin-left: 0;
    padding-bottom: 8px;
  }
  .radio-label {
    color: ${props => props.theme.color.black};
    margin-left: 12px;
    ${fontSize}
  }
`;


function EnquiryFormRadio(props) {
  const onChange = (e, value) => {
    props.setErrors({});
    props.handleChange(e);
    props.setFieldValue(props.name, value);

  };

  const onBlur = (e) => {
    props.handleBlur(e);
  };

  const {
    name, options, errors, label, selectedValue,
  } = props;

  return (
    <StyledField className="field" fontSize={[0, 0, 1]}>
      <label className="label">{label}</label>
      <div className="control">
        {options && options.map(({ label, value }) => (
          <label className="radio" key={value}>
            <input
              type="radio"
              name={name}
              checked={selectedValue === value}
              value={value}
              onChange={e => onChange(e, value)}
              onBlur={onBlur}
            />
            <span className="radio-label">{label}</span>
          </label>
        ))}
      </div>
      {errors[name] && <p className="help is-danger">{errors[name]}</p>}
    </StyledField>
  );
}

export default EnquiryFormRadio;
