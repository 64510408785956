import React, { Component } from 'react';
import {
  defineMessages,
  FormattedHTMLMessage,
  FormattedMessage, injectIntl,
} from 'react-intl';
import { Formik } from 'formik/dist/index';
import styled from 'styled-components';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import jsonp from 'jsonp';

import Paragraph from '../Atoms/Paragraph';
import { makeRequest } from '../../utilis/helpers';
import Button from '../Atoms/Button';
import theme from '../../styles/theme';
import FlexBox from '../Atoms/FlexBox';
import Section from '../Atoms/Section';
import Container from '../Atoms/Container';
import FormInput from '../Form/FormInput';
import EnquiryFormHeading from './EnquiryFormHeading';
import EnquiryFormRadio from './EnquiryFormRadio';
import EnquiryFormDatepicker from './EnquiryFormDatepicker';

import Heading from '../Atoms/Heading';
import {
  foodOptions, getBudgetOptions, getNumOfGuestOptions, rentalOptions, timeOptions, venueOptions,
} from './enquiryOptions';
import FormSelect from '../Form/FormSelect';
import Box from '../Atoms/Box';

const messages = defineMessages({
  formRequired: {
    id: 'event.form.required',
    defaultMessage: 'This field is required',
  },
  startTimeEndTime: {
    id: 'event.form.startTimeEndTime',
    defaultMessage: 'Start time and end time could not be the same',
  },
  numOfGuest: {
    id: 'event.form.numOfGuest',
    defaultMessage: '{num} people',
  },
  optionPeople: {
    id: 'event.form.people',
    defaultMessage: 'Number of Guest',
  },
  optionBudget: {
    id: 'event.form.budget',
    defaultMessage: 'Approximate Budget',
  },
  optionFood: {
    id: 'event.form.food',
    defaultMessage: 'Food & Beverage',
  },
  optionRentals: {
    id: 'event.form.rentals',
    defaultMessage: 'Rentals',
  },
  lessMoney: {
    id: 'event.form.lessMoney',
    defaultMessage: 'HK$ {cost} or less',
  },
  moreMoney: {
    id: 'event.form.moreMoney',
    defaultMessage: 'HK$ {cost} or more',
  },
  formSubmitted: {
    id: 'event.form.formSubmitted',
    defaultMessage: 'Thank you for your enquiry. Our event planning manager will get in touch with you as soon as possible.',
  },
});

const StyledForm = styled.form`
  width: 100%;
  input, select {
    border: 1px solid #73727d;
    padding: 4px 12px;
  }
`;

class EnquiryForm extends Component {
  commonError = null;

  submitForm = async (values, actions) => {
    const form = new FormData();
    form.append('companyName', values.companyName);
    form.append('contactPerson', values.contactPerson);
    form.append('email', values.email);
    form.append('tel', values.tel);
    form.append('venue', values.venue);
    form.append('other_venue', values.other_venue);
    form.append('date', values.date.format('DD-MMM-YYYY'));
    form.append('time', values.time);
    form.append('people', values.people);
    form.append('budget', values.budget);
    form.append('food', values.food);
    form.append('rentals', values.rentals);
    form.append('marketing', values.marketing);
    form.append('formType', 'enquiry');

    try {
      if (values.marketing) {
        jsonp(
          `${'https://imagiroconcepts.us17.list-manage.com/subscribe/post-json?u=d1c65d0c4becae18f4013777e&id=4b73a9f6c8&EMAIL='}${values.email}`,
          {
            param: 'c',
          },
        );
      }

      const res = await makeRequest({
        data: form,
      });
      actions.setSubmitting(false);
      actions.resetForm();
      actions.setStatus({ submitted: this.props.intl.formatMessage(messages.formSubmitted) });
    } catch (e) {
      actions.setSubmitting(false);
      actions.setErrors({ common: 'An error occurs. Please try again later.' });
    }
  };

  render() {
    const { intl, bgImg } = this.props;

    const numOfGuestOptions = getNumOfGuestOptions(intl, messages);
    const budgetOptions = getBudgetOptions(intl, messages);

    return (
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialStatus={{
          datepickerFocused: false,
        }}
        enableReinitialize
        initialValues={{
          // contact
          companyName: '',
          contactPerson: '',
          email: '',
          tel: '',
          // venue and date
          venue: '',
          other_venue: '',
          date: null,
          time: '',
          // Catering
          people: '',
          budget: '',
          food: '',
          rentals: '',
          marketing: true,
        }}
        validate={(values, props) => {
          this.commonError = null;
          const errors = {};
          // contact
          if (!values.companyName) {
            errors.companyName = intl.formatMessage(messages.formRequired);
          }
          if (!values.contactPerson) {
            errors.contactPerson = intl.formatMessage(messages.formRequired);
          }
          if (!values.email) {
            errors.email = intl.formatMessage(messages.formRequired);
          }
          if (!values.tel) {
            errors.tel = intl.formatMessage(messages.formRequired);
          }
          // venue and date
          if (!values.venue) {
            errors.venue = intl.formatMessage(messages.formRequired);
          }
          if (values.venue === 'others' && values.other_venue === '') {
            errors.other_venue = intl.formatMessage(messages.formRequired);
          }
          if (!values.date) {
            errors.date = intl.formatMessage(messages.formRequired);
          }
          if (!values.time) {
            errors.time = intl.formatMessage(messages.formRequired);
          }
          // Catering
          if (!values.people) {
            errors.people = intl.formatMessage(messages.formRequired);
          }
          if (!values.budget) {
            errors.budget = intl.formatMessage(messages.formRequired);
          }
          if (!values.food) {
            errors.food = intl.formatMessage(messages.formRequired);
          }
          if (!values.rentals) {
            errors.rentals = intl.formatMessage(messages.formRequired);
          }
          return errors;
        }}
        onSubmit={async (values, actions) => {
          this.submitForm(values, actions);
        }}
        render={({
          values,
          errors,
          status,
          touched,
          handleSubmit,
          setStatus,
          isSubmitting,
          isValid,
          setSubmitting,
          ...actions
        }) => (
          <Section
            py={[7, 7, 7, 8]}
            px={[4, 4, 4, 6]}
            position="relative"
            background="#f8f8f6"
            id="form"
            className="fp-auto-height"
          >
            <Container height={['auto', 'auto', 'auto', '100%']}>
              <FlexBox
                width="100%"
                height="100%"
                pt={[4, 4, 4, 7]}
                flexDirection="column"
                justifyContent="flex-start"
              >
                <Heading h={2} fontSize={[2, 2, 3]} pb={0} width="100%" textAlign="left">
                  <FormattedHTMLMessage id="event:form.intro.1" defaultMessage="Turn your event into the ideal catered occasion." />
                </Heading>
                <Heading h={2} fontSize={[2, 2, 3]} pb={[5, 5, 6]} width="100%" textAlign="left">
                  <FormattedHTMLMessage id="event:form.intro.2" defaultMessage="Make an enquiry." />
                </Heading>
                <StyledForm onSubmit={handleSubmit}>
                  <EnquiryFormHeading title={<FormattedHTMLMessage id="event:form.section.1" defaultMessage="Contact Information" />} />
                  <div className="field-body">
                    <FormInput
                      name="companyName"
                      value={values.companyName}
                      type="text"
                      label="Company Name"
                      errors={errors}
                      {...actions}
                    />
                    <FormInput
                      name="contactPerson"
                      value={values.contactPerson}
                      type="text"
                      label="Contact Person"
                      errors={errors}
                      {...actions}
                    />
                  </div>
                  <div className="field-body">
                    <FormInput
                      name="email"
                      value={values.email}
                      className="input"
                      type="email"
                      label="Email Address"
                      errors={errors}
                      {...actions}
                    />
                    <FormInput
                      name="tel"
                      value={values.tel}
                      className="input"
                      type="tel"
                      inputMode="numeric"
                      label="Phone"
                      errors={errors}
                      {...actions}
                    />
                  </div>
                  <EnquiryFormHeading mt={7} title={<FormattedHTMLMessage id="event:form.section.2" defaultMessage="Venue & Date" />} />
                  <EnquiryFormRadio
                    label="Venue"
                    name="venue"
                    selectedValue={values.venue}
                    options={venueOptions}
                    errors={errors}
                    {...actions}
                  />
                  {values.venue && values.venue === 'others' && (
                  <FormInput
                    name="other_venue"
                    value={values.other_venue}
                    className="input"
                    type="string"
                    errors={errors}
                    {...actions}
                  />
                  )}
                  <div
                    className="field-body"
                    style={{
                      width: '100%',
                      maxWidth: '450px',
                      marginTop: '48px',
                    }}
                  >
                    <EnquiryFormDatepicker
                      name="date"
                      type="date"
                      value={values.date}
                      label={<FormattedHTMLMessage id="event:form.field.date" defaultMessage="Preferred Date" />}
                      errors={errors}
                      onDateChange={date => actions.setFieldValue('date', date)}
                      focused={status.datepickerFocused}
                      onFocusChange={({ focused }) => setStatus(
                        { datepickerFocused: focused },
                      )}
                      {...actions}
                    />
                    <FormSelect
                      name="time"
                      label={<FormattedHTMLMessage id="event:form.field.time" defaultMessage="Time" />}
                      selections={timeOptions}
                      errors={errors}
                      value={values.time}
                      {...actions}
                    />
                  </div>
                  <EnquiryFormHeading mt={7} title={<FormattedHTMLMessage id="event:form.section.3" defaultMessage="Catering Details" />} />
                  <div className="field-body">
                    <FormInput
                      name="people"
                      value={values.people}
                      type="number"
                      label={intl.formatMessage(messages.optionPeople)}
                      errors={errors}
                      pb={[3, 3, 4]}
                      min={0}
                      {...actions}
                    />
                  </div>
                  <div className="field-body">
                    {/* <EnquiryFormRadio */}
                    {/*  name="people" */}
                    {/*  label={intl.formatMessage(messages.optionPeople)} */}
                    {/*  options={numOfGuestOptions} */}
                    {/*  errors={errors} */}
                    {/*  {...actions} */}
                    {/* /> */}
                    <EnquiryFormRadio
                      name="budget"
                      selectedValue={values.budget}
                      label={intl.formatMessage(messages.optionBudget)}
                      options={budgetOptions}
                      errors={errors}
                      {...actions}
                    />
                    <EnquiryFormRadio
                      name="food"
                      selectedValue={values.food}
                      label={intl.formatMessage(messages.optionFood)}
                      options={foodOptions}
                      errors={errors}
                      {...actions}
                    />
                    <EnquiryFormRadio
                      name="rentals"
                      selectedValue={values.rentals}
                      label={intl.formatMessage(messages.optionRentals)}
                      options={rentalOptions}
                      errors={errors}
                      {...actions}
                    />
                  </div>
                  <div className="field-body">
                    <label className="checkbox">
                      <input
                        name="marketing"
                        type="checkbox"
                        checked={values.marketing}
                        onChange={actions.handleChange}
                      />
                      <Box as="span" ml={2}><FormattedHTMLMessage id="event:form.marketing" defaultMessage="I agree to receive marketing offers and promotions from Imagiro." /></Box>
                    </label>
                  </div>
                  <EnquiryFormHeading mt={3} />
                  {errors.common && (
                    <Paragraph fontSize={1} color={theme.color.red}>
                      {errors.common}
                    </Paragraph>
                  )}
                  {status && status.submitted && (
                    <Paragraph fontSize={1} color={theme.color.gold}>
                      {status.submitted}
                    </Paragraph>
                  )}
                  <FlexBox width="100%">
                    <Button
                      width={120}
                      primary
                      type="submit"
                      text="send"
                      loading={isSubmitting}
                      disabled={isSubmitting || !isValid}
                    />
                  </FlexBox>
                </StyledForm>
              </FlexBox>
            </Container>
          </Section>
        )}
      />
    );
  }
}

export default injectIntl(EnquiryForm);
