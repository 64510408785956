import React, { Component } from 'react';
import { graphql } from 'gatsby';

import { PageHelmet } from '../components/PageHelmet';
import EnquiryForm from '../components/Enquiry/EnquiryForm';

// ********************************* UI Template (Shared with CMS) *********************************
export class EnquiryPageTemplate extends Component {
  render() {
    const {
      title, description, bgImg, cms = false,
    } = this.props;
    return (
      <div>
        <PageHelmet title={title} description={description} />
        <EnquiryForm bgImg={bgImg} />
      </div>
    );
  }
}

// ********************************* Render page *********************************
const EnquiryPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return <EnquiryPageTemplate {...frontmatter} />;
};

export default EnquiryPage;

// ********************************* Data graphql *********************************
export const pageQuery = graphql`
  query EnquiryPageWithId($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        description
        bgImg {
          alt
          image {
            childImageSharp {
              fluid(maxWidth: 1024, quality: 90) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
      }
    }
  }
`;
