import React from 'react';
import styled from 'styled-components';
import { fontSize } from 'styled-system';
import { SingleDatePicker } from 'react-dates';

const StyledField = styled.div`
  .SingleDatePicker, .SingleDatePickerInput, .DateInput {
    width: 100%;
    background: transparent;
  }
  input {
    width: 100%;
    background: transparent;
    border: 1px solid #73727d;
    padding: 4px 12px;
    height: 3rem;
    box-sizing: border-box;
    &:focus {
      box-shadow: none;
    }
  }
  label {
    color: ${props => props.theme.color.gray};
    ${fontSize}
  }
`;

function EnquiryFormDatepicker(props) {
  const {
    name, label, value, onDateChange, focused, onFocusChange, errors,
  } = props;

  return (
    <StyledField className="field" fontSize={[0, 0, 1]}>
      <label className="label">{label}</label>
      <div className="control">
        <SingleDatePicker
          id="datepicker"
          placeholder="MM/DD/YYYY"
          date={value}
          onDateChange={onDateChange}
          focused={focused}
          onFocusChange={onFocusChange}
        />
      </div>
      {errors[name] && <p className="help is-danger">{errors[name]}</p>}
    </StyledField>
  );
}

export default EnquiryFormDatepicker;
