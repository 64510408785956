export const eventIntlMessages = {
  formRequired: {
    id: 'event.form.required',
    defaultMessage: 'This field is required',
  },
  startTimeEndTime: {
    id: 'event.form.startTimeEndTime',
    defaultMessage: 'Start time and end time could not be the same',
  },
  numOfGuest: {
    id: 'event.form.numOfGuest',
    defaultMessage: '{num} people',
  },
  optionPeople: {
    id: 'event.form.people',
    defaultMessage: 'Number of Guest',
  },
  optionBudget: {
    id: 'event.form.budget',
    defaultMessage: 'Approximate Budget',
  },
  optionFood: {
    id: 'event.form.food',
    defaultMessage: 'Food & Beverage',
  },
  optionRentals: {
    id: 'event.form.rentals',
    defaultMessage: 'Rentals',
  },
  lessMoney: {
    id: 'event.form.lessMoney',
    defaultMessage: 'HK$ {cost} or less',
  },
  moreMoney: {
    id: 'event.form.moreMoney',
    defaultMessage: 'HK$ {cost} or more',
  },
};

export const timeOptions = {
  placeholder: 'Select a time',
  list: [
    {
      label: '8am - 11am',
      value: '8am-11am',
    },
    {
      label: '11am - 3pm',
      value: '11am-3pm',
    },
    {
      label: '3pm - 6pm',
      value: '3pm-6pm',
    },
    {
      label: '6pm - 11pm',
      value: '6pm-11pm',
    },
  ],
};

export const venueOptions = [
  {
    label: 'AMMO',
    value: 'ammo',
  },
  {
    label: 'Gyotaku',
    value: 'gyotaku',
  },
  {
    label: 'Sushi Ono',
    value: 'sushiono',
  },
  {
    label: 'Tenzushi',
    value: 'tenzushi',
  },
  {
    label: 'Others (Please specify address)',
    value: 'others',
  },
];

export const getNumOfGuestOptions = (intl, messages) => ([
  {
    label: intl.formatMessage(messages.numOfGuest, { num: '10' }),
    value: '10',
  },
  {
    label: intl.formatMessage(messages.numOfGuest, { num: '11-20' }),
    value: '11-20',
  },
  {
    label: intl.formatMessage(messages.numOfGuest, { num: '21-40' }),
    value: '21-40',
  },
  {
    label: intl.formatMessage(messages.numOfGuest, { num: '40+' }),
    value: '40+',
  },
]);

export const getBudgetOptions = (intl, messages) => ([
  {
    label: intl.formatMessage(messages.lessMoney, { cost: '10,000' }),
    value: '10000',
  },
  {
    label: intl.formatMessage(messages.lessMoney, { cost: '20,000' }),
    value: '20000',
  },
  {
    label: intl.formatMessage(messages.lessMoney, { cost: '30,000' }),
    value: '30000',
  },
  {
    label: intl.formatMessage(messages.lessMoney, { cost: '40,000' }),
    value: '40000',
  },
  {
    label: intl.formatMessage(messages.moreMoney, { cost: '40,000' }),
    value: '40000+',
  },
]);

export const foodOptions = [
  {
    label: 'Breakfast',
    value: 'breakfast',
  },
  {
    label: 'Lunch',
    value: 'lunch',
  },
  {
    label: 'Dinner',
    value: 'dinner',
  },
  {
    label: 'Cocktail / Canapes',
    value: 'cocktail',
  },
  {
    label: 'Drinks',
    value: 'drinks',
  },
];

export const rentalOptions = [
  {
    label: 'Furniture',
    value: 'furniture',
  },
  {
    label: 'Crockery',
    value: 'crockery',
  },
  {
    label: 'Glassware',
    value: 'glassware',
  },
  {
    label: 'None',
    value: 'none',
  },
];
