import React from 'react';
import styled from 'styled-components';
import { color, space, width, fontSize } from 'styled-system';
import theme from '../../styles/theme';

const StyledField = styled.div`
  label {
    color: ${props => props.theme.color.gray};
    ${fontSize}
  }
  .select {
    height: 100%;
  }
  select {
    &:focus {
      box-shadow: inherit;
    }
  }
  ${space}
  ${width}
`;

const StyledSelect = styled.select`
  ${color}
`;

function FormSelect(props) {
  const onChange = (e) => {
    props.setErrors({});
    props.handleChange(e);
  };

  const onBlur = (e) => {
    props.handleBlur(e);
  };

  const {
    name, errors, selections, value, label,
  } = props;

  if (!selections) {
    return null;
  }

  return (
    <StyledField className="field" fontSize={[0, 0, 1]}>
      <label className="label">{label}</label>
      <div className="control">
        <div className="select">
          <StyledSelect
            name={name}
            defaultValue=""
            onChange={onChange}
            onBlur={onBlur}
            color={value === '' ? `${theme.color.gray} !important` : `${theme.color.black} !important`}
          >
            <option value="" disabled>
              {selections.placeholder}
            </option>
            {selections.list && selections.list.map(v => (
              <option key={v.value} value={v.value}>
                {v.label}
              </option>
            ))}
          </StyledSelect>
        </div>
      </div>
      {errors[name] && <p className="help is-danger">{errors[name]}</p>}
    </StyledField>
  );
}

export default FormSelect;
