import React from 'react';
import styled from 'styled-components';
import { space, width, fontSize } from 'styled-system';

const StyledField = styled.div`
  input {
    box-sizing: border-box;
    &:focus {
      box-shadow: none;
    }
  }
  label {
    color: ${props => props.theme.color.gray};
    ${fontSize}
  }
  ${space}
  ${width}
`;

function FormInput(props) {
  const onChange = (e) => {
    props.setErrors({});
    props.handleChange(e);
  };

  const onBlur = (e) => {
    props.handleBlur(e);
  };

  const {
    name, placeholder, type, errors, label, max, min, inputWidth, pb, value,
  } = props;

  return (
    <StyledField className="field" width={inputWidth || 'auto'} fontSize={[0, 0, 1]} pb={pb}>
      <label className="label">{label}</label>
      <div className="control">
        {type !== 'textarea' && (
        <input
          value={value}
          name={name}
          className="input"
          type={type}
          placeholder={placeholder || ''}
          onChange={onChange}
          onBlur={onBlur}
          max={max}
          min={min}
        />
        )}
        {type === 'textarea' && (
        <textarea
          value={value}
          name="message"
          className="textarea"
          placeholder={placeholder || ''}
          onChange={onChange}
          onBlur={onBlur}
        />
        )}
      </div>
      {errors[name] && <p className="help is-danger">{errors[name]}</p>}
    </StyledField>
  );
}

export default FormInput;
